import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/hero"

class SuccessStories extends Component {
  reveal () {
    const ScrollReveal = require('scrollreveal').default

    ScrollReveal({
      viewFactor: 0.4,
      distance: '100px',
      duration: 1000
    });

    ScrollReveal().reveal('.sr-1', {
      delay: 0
    });

    ScrollReveal().reveal('.sr-r', {
      origin: 'right'
    });
  }

  componentDidMount () {
    if (typeof window !== 'undefined') {
      this.reveal();
    }
  }

  render () {
    return (
      <Layout>
        <SEO title="Thank you for getting in touch! – Data-driven marketing automation for expanding your eCommerce store" />
        <Hero title="Thank you for getting in&nbsp;touch!" subtitle="We appreciate you contacting us. One of&nbsp;our colleagues will get back to&nbsp;you shortly. Have a&nbsp;great day!" button="false" classes="heroes--full has-text-centered" />

        <div className="container container--pad pad-b-6" />
      </Layout>
    )
  }
}

export default SuccessStories
